import React from 'react';
import { ClipboardCheck, ClipboardList, Calendar } from 'lucide-react';

const LoadingValue = () => (
  <span className="animate-pulse">
    <span className="h-8 w-16 bg-gray-200 rounded inline-block"></span>
  </span>
);

const getColorClasses = (color) => ({
  blue: { bg: "bg-blue-600", light: "bg-blue-50", text: "text-blue-600" },
  purple: { bg: "bg-purple-600", light: "bg-purple-50", text: "text-purple-600" },
  green: { bg: "bg-green-600", light: "bg-green-50", text: "text-green-600" }
}[color]);

const StatsCards = ({ isLoading, surveyStats }) => {
  const statsData = [
    {
      title: "Completed Surveys",
      value: isLoading ? <LoadingValue /> : surveyStats.totalCompleted.toLocaleString(),
      icon: ClipboardCheck,
      color: "green"
    },
    {
      title: "Pending Surveys",
      value: isLoading ? 
        <LoadingValue /> : 
        `${surveyStats.totalPending.toLocaleString()}/${(surveyStats.totalPending + surveyStats.totalCompleted).toLocaleString()}`,
      icon: ClipboardList,
      color: "purple"
    },
    {
      title: "Monthly Survey Count",
      value: isLoading ? <LoadingValue /> : surveyStats.monthlyCount.toLocaleString(),
      icon: Calendar,
      color: "blue"
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pt-10">
      {statsData.map((stat, index) => {
        const colorClasses = getColorClasses(stat.color);
        const Icon = stat.icon;
        return (
          <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className={`h-1 ${colorClasses.bg}`} />
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div className={`p-3 rounded-lg ${colorClasses.light}`}>
                  <Icon className={`h-6 w-6 ${colorClasses.text}`} />
                </div>
                <div className="text-2xl font-bold text-gray-800">{stat.value}</div>
              </div>
              <div className="mt-4 text-sm font-medium text-gray-600">{stat.title}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatsCards;