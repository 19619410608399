import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Cell } from 'recharts';
const LoadingItem = () => (
  <div className="grid grid-cols-5 gap-4 py-3">
    <div className="col-span-2 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const SurveyBarChart = ({ isLoading, dailySurveyData, assetReports }) => {
  const barColors = [
    '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5'
  ];

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-6">Daily Survey Count</h2>
        <div style={{ height: '400px' }} className="h-full flex items-center justify-center">
          <LoadingItem />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-800 mb-6">Daily Survey Count</h2>
      <div style={{ height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={dailySurveyData.length > 0 ? dailySurveyData : assetReports} 
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis 
              dataKey="ward_name" 
              tickSize={6}
              height={60}
              tick={{
                angle: -45,
                textAnchor: 'end',
                fontSize: 12
              }}
            />
            <YAxis />
            <Tooltip
              contentStyle={{
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px'
              }}
            />
            <Bar dataKey="completed" fill="#8884d8" name="Completed">
              {(dailySurveyData.length > 0 ? dailySurveyData : assetReports).map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={barColors[index % barColors.length]} 
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SurveyBarChart;