import React, { useState, useMemo } from 'react';
import { Building, AlertCircle, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, ChevronDown, ChevronUp, Search, X, ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';

const LoadingText = ({ width = "w-40", height = "h-6" }) => (
  <div className={`inline-block ${width} ${height} animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded relative overflow-hidden`}>
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer" />
  </div>
);

const LoadingItem = () => (
  <div className="grid grid-cols-5 gap-4 py-3">
    <div className="col-span-2 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center"><div className="h-4 bg-gray-200 rounded w-12"></div></div>
    <div className="flex justify-center"><div className="h-4 bg-gray-200 rounded w-12"></div></div>
    <div className="flex justify-center"><div className="h-4 bg-gray-200 rounded w-12"></div></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
    <AlertCircle className="h-5 w-5" /><span>{message}</span>
  </div>
);

const SearchBar = ({ searchTerm, onSearchChange, onClearSearch, sortConfig, onSort }) => (
  <div className="mb-4 flex flex-col sm:flex-row gap-3 items-start sm:items-center">
    <div className="relative flex-1 max-w-md">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Search wards, zones..."
        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      />
      <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      {searchTerm && (
        <button onClick={onClearSearch} className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600">
          <X className="h-5 w-5" />
        </button>
      )}
    </div>
    <div className="flex gap-2 overflow-x-auto w-full sm:w-auto">
      <button
        onClick={() => onSort('completed')}
        className="px-3 py-1.5 border border-gray-300 rounded-md text-sm text-gray-600 hover:bg-gray-50"
      >
        Completed {sortConfig.field === 'completed' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↕'}
      </button>
      <button
        onClick={() => onSort('pending')}
        className="px-3 py-1.5 border border-gray-300 rounded-md text-sm text-gray-600 hover:bg-gray-50"
      >
        Pending {sortConfig.field === 'pending' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↕'}
      </button>
      <button
        onClick={() => onSort('total')}
        className="px-3 py-1.5 border border-gray-300 rounded-md text-sm text-gray-600 hover:bg-gray-50"
      >
        Total {sortConfig.field === 'total' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↕'}
      </button>
    </div>
  </div>
);
const SortButton = ({ field, sortConfig, onSort, children }) => (
  <button onClick={() => onSort(field)} className="flex items-center text-left gap-1 transition-colors hover:text-gray-900">
    {children}
    <div className="flex flex-col">
      <ArrowUp className={`h-2.5 w-2.5 ${sortConfig.field === field && sortConfig.direction === 'asc' ? 'text-blue-600' : 'text-gray-400'}`} />
      <ArrowDown className={`h-2.5 w-2.5 mt-[-2px] ${sortConfig.field === field && sortConfig.direction === 'desc' ? 'text-blue-600' : 'text-gray-400'}`} />
    </div>
  </button>
);

const UnauthorizedSurveyHeader = () => (
  <div className="hidden lg:grid grid-cols-5 gap-4 py-3 border-b border-gray-200 bg-gray-50 rounded-t-lg px-4">
    <div className="col-span-2">
      <h4 className="text-sm font-medium text-gray-600">Ward Details</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Completed</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Pending</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Total</h4>
    </div>
  </div>
);

const ZoneGroupHeader = ({ zoneName, isExpanded, onToggle, stats }) => (
  <div className="bg-gray-50 px-4 py-3 cursor-pointer hover:bg-gray-100 transition-colors border-b border-gray-200" onClick={onToggle}>
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        <h3 className="font-medium text-gray-800">{zoneName}</h3>
      </div>
      <div className="flex gap-6 text-sm text-gray-600">
        <span>Completed: {stats.completed}</span>
        <span>Pending: {stats.pending}</span>
        <span>Total: {stats.total}</span>
      </div>
    </div>
  </div>
);

const UnauthorizedSurveyItem = ({ report, onCompletedClick }) => (
  <div className="flex flex-col lg:grid lg:grid-cols-5 gap-4 py-3 border-b border-gray-100 last:border-0 px-4 hover:bg-gray-50">
    <div className="col-span-2">
      <h3 className="font-medium text-gray-800">{report.ward_name}</h3>
      <div className="text-xs text-gray-500 mt-1">
        <p>{report.org_name}</p>
        <p>{report.zone_name}</p>
      </div>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Completed:</span>
      <button onClick={() => onCompletedClick(report)} className="font-medium text-green-600 hover:text-green-700 transition-colors hover:underline">
        {report.completed || '0'}
      </button>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Pending:</span>
      <span className="font-medium text-blue-600">{report.pending || '0'}</span>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Total:</span>
      <span className="font-medium text-gray-700">{report.total || '0'}</span>
    </div>
  </div>
);

const PaginationControls = ({ currentPage, totalPages, onPageChange, totalItems, itemsPerPage, showingFrom, showingTo }) => (
  <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-3 border-t border-gray-200 bg-white">
    <div className="text-sm text-gray-700">
      Showing <span className="font-medium">{showingFrom}</span>{' '}
      to <span className="font-medium">{showingTo}</span>{' '}
      of <span className="font-medium">{totalItems}</span> results
    </div>
    <div className="flex items-center gap-2">
      <button onClick={() => onPageChange(1)} disabled={currentPage === 1} 
        className="p-1 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronsLeft className="h-4 w-4" />
      </button>
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} 
        className="p-1 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronLeft className="h-4 w-4" />
      </button>
      <span className="px-4 py-1 text-sm text-gray-700">
        Page {currentPage} of {totalPages}
      </span>
      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} 
        className="p-1 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronRight className="h-4 w-4" />
      </button>
      <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} 
        className="p-1 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronsRight className="h-4 w-4" />
      </button>
    </div>
  </div>
);

const UnauthorizedSurveyTable = ({ isLoading, assetReports, onCompletedClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [expandedZones, setExpandedZones] = useState({});
  const [groupByZone, setGroupByZone] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ field: null, direction: null });

  const sortData = (data) => {
    if (!sortConfig.field) return data;
    
    const sortedData = [...data].sort((a, b) => {
      // Convert string numbers to actual numbers for comparison
      const aValue = parseInt(a[sortConfig.field]) || 0;  // 2490, 1864, etc
      const bValue = parseInt(b[sortConfig.field]) || 0;  
      
      // Higher to lower: 2490, 1864, 1802, 1296
      if (sortConfig.direction === 'desc') {
        return bValue - aValue;
      }
      // Lower to higher: 1296, 1802, 1864, 2490
      return aValue - bValue;
    });
  
    return sortedData;
  };
  
  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      // Start with desc (high to low) on first click
      direction: prev.field === field ? 
        (prev.direction === 'desc' ? 'asc' : 'desc') : 
        'desc'
    }));
  };

  const filteredData = useMemo(() => {
    return sortData(assetReports.filter(report => {
      if (!searchTerm) return true;
      const searchLower = searchTerm.toLowerCase();
      return (
        report.ward_name?.toLowerCase().includes(searchLower) ||
        report.zone_name?.toLowerCase().includes(searchLower) ||
        report.org_name?.toLowerCase().includes(searchLower)
      );
    }));
  }, [assetReports, searchTerm, sortConfig]);

  const groupedData = useMemo(() => {
    if (!groupByZone) return null;
    
    // First group the data
    const grouped = filteredData.reduce((acc, report) => {
      const zoneName = report.zone_name || 'Unassigned';
      if (!acc[zoneName]) {
        acc[zoneName] = {
          reports: [],
          stats: { completed: 0, pending: 0, total: 0 }
        };
      }
      acc[zoneName].reports.push(report);
      acc[zoneName].stats.completed += parseInt(report.completed) || 0;
      acc[zoneName].stats.pending += parseInt(report.pending) || 0;
      acc[zoneName].stats.total += parseInt(report.total) || 0;
      return acc;
    }, {});
  
    // Sort the zones based on their stats totals
    const sortedEntries = Object.entries(grouped).sort(([, a], [, b]) => {
      if (sortConfig.field) {
        const aValue = parseInt(a.stats[sortConfig.field]) || 0;
        const bValue = parseInt(b.stats[sortConfig.field]) || 0;
        return sortConfig.direction === 'desc' ? bValue - aValue : aValue - bValue;
      }
      return 0;
    });
  
    // Convert back to object after sorting
    return Object.fromEntries(sortedEntries);
  }, [filteredData, groupByZone, sortConfig]);

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const showingFrom = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const showingTo = Math.min(currentPage * itemsPerPage, totalItems);
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };
  const toggleZone = (zoneName) => {
    setExpandedZones(prev => ({...prev, [zoneName]: !prev[zoneName]}));
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortConfig]);

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
          <h2 className="text-lg font-semibold text-gray-800">Asset detail</h2>
          <button
            onClick={() => setGroupByZone(!groupByZone)}
            className={`px-4 py-2 rounded-md border transition-colors ${
              !groupByZone 
                ? 'border-blue-600 text-blue-600 bg-blue-50' 
                : 'border-gray-300 text-gray-600 hover:bg-gray-50'
            }`}
          >
            {!groupByZone ? 'Group by Zone' : 'Show Ward data'}
          </button>
        </div>

        <SearchBar
  searchTerm={searchTerm}
  onSearchChange={setSearchTerm}
  onClearSearch={handleClearSearch}
  sortConfig={sortConfig}
  onSort={handleSort}
/>

        <div className="rounded-lg border border-gray-200">
          {!groupByZone && <UnauthorizedSurveyHeader sortConfig={sortConfig} onSort={handleSort} />}
          
          <div className="divide-y divide-gray-200">
            {isLoading ? (
              <div className="px-4">{[1, 2, 3].map((i) => (<LoadingItem key={i} />))}</div>
            ) : groupByZone ? (
              Object.entries(groupedData || {}).map(([zoneName, data]) => (
                <div key={zoneName}>
                  <ZoneGroupHeader
                    zoneName={zoneName}
                    isExpanded={expandedZones[zoneName]}
                    onToggle={() => toggleZone(zoneName)}
                    stats={data.stats}
                    sortConfig={sortConfig}
                    onSort={handleSort}
                  />
                  {expandedZones[zoneName] && (
                    <div className="divide-y divide-gray-100">
                      {data.reports.map((report, index) => (
                        <UnauthorizedSurveyItem
                          key={index}
                          report={report}
                          onCompletedClick={onCompletedClick}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              getCurrentItems().map((report, index) => (
                <UnauthorizedSurveyItem
                  key={index}
                  report={report}
                  onCompletedClick={onCompletedClick}
                />
              ))
            )}

            {!isLoading && filteredData.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No records found {searchTerm && `matching "${searchTerm}"`}
              </div>
            )}
          </div>

          {!isLoading && !groupByZone && filteredData.length > 0 && (
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              showingFrom={showingFrom}
              showingTo={showingTo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { UnauthorizedSurveyTable };