import axios from 'axios';
import { API_URL } from '../config';

const api = axios.create({
    baseURL: API_URL,
    timeout: 30000,
    withCredentials: false,
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
    }
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = userData?.authToken;

        // Handle image requests differently
        if (config.url.includes('/uploads/survey/')) {
            config.responseType = 'blob';
            config.headers = {
                ...config.headers,
                'Authorization': token,
                'Accept': 'image/*'
            };
        } else if (config.url.includes('/survey/report/data/')) {
            config.responseType = 'blob';
            config.headers = {
                ...config.headers,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            };

            if (config.params) {
                config.params = {
                    org: config.params.org,
                    ward: config.params.ward
                };
            }
        } else {
            config.headers['Authorization'] = token;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor remains the same
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API Error:', error);
        if (error.code === 'ERR_NETWORK') {
            return Promise.reject(new Error('Server not responding. Please try again later.'));
        }
        return Promise.reject(error?.response?.data || error);
    }
);

export default api;