import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardCheck, ClipboardList, Calendar, Building, ArrowRight, AlertCircle, X, ChevronRight } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { useSurvey } from '../context/surveycontext';
import api from '../apiConfig/api';
import { UnauthorizedSurveyTable } from '../components/unauthorizedtable';
import AssetDetailsSection from '../components/assetdetails';



import { OrgSummaryTable } from '../components/org_summary';
import WelcomeHeader from '../components/welcomeheader';
import StatsCards from '../components/statscard';
import SurveyBarChart from '../components/surveychart';
const LoadingText = ({ width = "w-40", height = "h-6" }) => (
  <div className={`inline-block ${width} ${height} animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded relative overflow-hidden`}>
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer" />
  </div>
);

const LoadingValue = () => (
  <span className="animate-pulse">
    <span className="h-8 w-16 bg-gray-200 rounded inline-block"></span>
  </span>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
    <AlertCircle className="h-5 w-5" />
    <span>{message}</span>
  </div>
);

const LoadingItem = () => (
  <div className="grid grid-cols-5 gap-4 py-3">
    <div className="col-span-2 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const CompletedSurveyHeader = () => (
  <div className="hidden lg:grid grid-cols-5 gap-4 py-3 border-b border-gray-200 bg-gray-50 rounded-t-lg px-4">
    <div className="col-span-2">
      <h4 className="text-sm font-medium text-gray-600">Ward Details</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Completed</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Pending</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Total</h4>
    </div>
  </div>
);

const CompletedSurveyItem = ({ report, onCompletedClick }) => (
  <div className="flex flex-col lg:grid lg:grid-cols-5 gap-4 py-3 border-b border-gray-100 last:border-0 px-4 hover:bg-gray-50">
    <div className="col-span-2">
      <h3 className="font-medium text-gray-800">{report.ward_name}</h3>
      <div className="text-xs text-gray-500 mt-1">
        <p>{report.org_name}</p>
        <p>{report.zone_name}</p>
      </div>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Completed:</span>
      <button
        onClick={() => onCompletedClick(report)}
        className="font-medium text-green-600 hover:text-green-700 transition-colors hover:underline"
      >
        {report.completed || '0'}
      </button>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Pending:</span>
      <span className="font-medium text-blue-600">{report.pending || '0'}</span>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Total:</span>
      <span className="font-medium text-gray-700">{report.total || '0'}</span>
    </div>
  </div>
);

const AssetDetailsModal = ({ isOpen, onClose, assetDetails, isLoading, wardInfo, onAssetClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 flex items-center justify-between sticky top-0 bg-white">
          <h2 className="text-lg font-semibold text-gray-800">
            Asset Details - {wardInfo?.ward_name || 'N/A'} - Survey Completed
          </h2>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="overflow-auto p-4">
          {isLoading ? (
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="animate-pulse space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              ))}
            </div>
          ) : assetDetails.length > 0 ? (
            <div className="grid gap-4">
              {assetDetails.map((asset, index) => (
                <div 
                  key={index} 
                  onClick={() => onAssetClick(asset.id)}
                  className="border border-gray-200 rounded-lg p-4 hover:shadow-lg hover:border-gray-300 transition-all cursor-pointer"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Street Name</p>
                      <p className="font-medium">{asset.street_name || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Door No</p>
                      <p className="font-medium">{asset.new_door || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Owner Name</p>
                      <p className="font-medium">{asset.owner || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Usage</p>
                      <p className={`font-medium ${
                        asset.usage?.toLowerCase() === 'commercial' || 
                        asset.usage?.toLowerCase() === 'mixed' 
                          ? 'text-red-600' 
                          : 'text-gray-800'
                      }`}>
                        {asset.usage || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end text-sm text-blue-600">
                    View Details <ChevronRight className="h-4 w-4" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No asset details available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const HomePage = () => {
  const navigate = useNavigate();
  const { setIsSurveyEnabled, setSurveyParams, setZoneName } = useSurvey();
  const dataFetchedRef = useRef(false);
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [assetReports, setAssetReports] = useState([]);
  const [surveyDate, setSurveyDate] = useState('');
  const [userData, setUserData] = useState({
    designation: '',
    organizationName: '',
    userId: '',
    orgId: '',
    zoneName: '',
    zoneId: '',
    orgCode: ''
  });
  const [surveyStats, setSurveyStats] = useState({
    totalCompleted: 0,
    totalPending: 0,
    monthlyCount: 0
  });
  const [wardData, setWardData] = useState([]);
  const [dailySurveyData, setDailySurveyData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWard, setSelectedWard] = useState(null);
  const [assetDetails, setAssetDetails] = useState([]);
  const [isLoadingAssetDetails, setIsLoadingAssetDetails] = useState(false);

  const isAuthorizedRole = role => ['Tax Collector', 'Revenue Assistant', ].includes(role);
  const isHighLevelRole = role => [
    'CHIEF ENGINEER',
    'CMA',
    'CMAADMIN',
    'JOINT COMMISSIONER OF MUNICIPAL ADMINISTRATION',
    'JOINT DIRECTOR',
    'JOINT DIRECTOR (CORPN)',
    'JOINT DIRECTOR (CORPORATION)',
    'SYSTEM ANALYST',
    
  ].includes(role.toUpperCase());
  const getHomeData = async () => {
    try {
      const homeDataResponse = await api.get('/api/v1/user/homedata');
      console.log('Home Data Response:', homeDataResponse);
      
      if (homeDataResponse?.data?.user) {
        const user = homeDataResponse.data.user;
        const role = user.role || 'No designation';
        console.log('Setting user role:', role);
  
        // Store header info in localStorage
        const headerInfo = {
          name: user.name || '',
          role: role,
          designation: role,
          organizationName: user.resp?.OrgName || 'Organization'
        };
        localStorage.setItem('headerInfo', JSON.stringify(headerInfo));
        localStorage.setItem('report_data', role); // Add this line
        
        // Set role first
        await setUserRole(role);
        
        let orgName = 'Organization';
        if (user.resp && typeof user.resp === 'object') {
          if (user.resp.OrgName) {
            orgName = user.resp.OrgName;
          } else if (user.resp.wards && user.resp.wards.length > 0) {
            orgName = user.resp.wards[0].OrgName;
          }
        }
  
        // Then set other user data
        setUserData(prevData => ({
          ...prevData,
          designation: role,
          organizationName: orgName,
          zoneName: isAuthorizedRole(role) ? '' : ''
        }));
        
        setUserName(user.name || '');
        setSurveyDate(homeDataResponse?.data?.data?.[0]?.created || new Date().toISOString());
        
        return {
          mobile: user.mobile,
          role: role
        };
      }
    } catch (error) {
      console.error('Home Data API Error:', error);
      setGlobalError('Failed to load user data');
    }
    return null;
  };
  const barColors = [
    '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5'
  ];

  const getColorClasses = (color) => ({
    blue: { bg: "bg-blue-600", light: "bg-blue-50", text: "text-blue-600" },
    purple: { bg: "bg-purple-600", light: "bg-purple-50", text: "text-purple-600" },
    green: { bg: "bg-green-600", light: "bg-green-50", text: "text-green-600" }
  }[color]);

  const statsData = [
    {
      title: "Completed Surveys",
      value: isLoading ? <LoadingValue /> : surveyStats.totalCompleted.toLocaleString(),
      icon: ClipboardCheck,
      color: "green"
    },
    {
      title: "Pending Surveys",
      value: isLoading ? 
        <LoadingValue /> : 
        `${surveyStats.totalPending.toLocaleString()}/${(surveyStats.totalPending + surveyStats.totalCompleted).toLocaleString()}`,
      icon: ClipboardList,
      color: "purple"
    },
    {
      title: "Monthly Survey Count",
      value: isLoading ? <LoadingValue /> : surveyStats.monthlyCount.toLocaleString(),
      icon: Calendar,
      color: "blue"
    }
  ];

  const handleAssetClick = (assetId) => {
    if (!assetId) return;
    navigate(`/report/${assetId}`);
  };

  const handleCompletedClick = async (wardInfo) => {
    try {
      setIsLoadingAssetDetails(true);
      setSelectedWard(wardInfo);
      setIsModalOpen(true);

      const response = await api.get('/api/v1/asset/report', {
        params: {
          org: wardInfo.org_name,
          zone: wardInfo.zone_name,
          ward: wardInfo.ward_name
        }
      });

      setAssetDetails(response.data.data || []);
    } catch (error) {
      console.error('Error fetching asset details:', error);
      setAssetDetails([]);
    } finally {
      setIsLoadingAssetDetails(false);
    }
  };
  const fetchUsageData = async (wardInfo) => {
    try {
      if (!wardInfo?.org_name || !wardInfo?.zone_name || !wardInfo?.ward_name) {
        console.error('Missing required parameters for usage data fetch');
        return null;
      }
      
      const params = new URLSearchParams({
        org: wardInfo.org_name,
        zone: wardInfo.zone_name,
        ward: wardInfo.ward_name
      });
  
      const response = await api.get(`/api/v1/asset/report?${params}`);
      const data = response.data.data || [];
  
      // Count usage types
      const usageCounts = {
        Residential: 0,
        Commercial: 0,
        Government: 0,
        Educational: 0,
        Industrial: 0,
        Mixed: 0
      };
  
      data.forEach(item => {
        if (item.usage && usageCounts.hasOwnProperty(item.usage)) {
          usageCounts[item.usage]++;
        }
      });
  
      // Convert to chart format
      return Object.entries(usageCounts).map(([type, count]) => ({
        type,
        count
      }));
    } catch (error) {
      console.error('Error fetching usage data:', error);
      return null;
    }
  };

  const fetchData = async () => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    
    try {
      setIsLoading(true);
      setGlobalError(null);
      const homeData = await getHomeData();
      
      if (!homeData?.mobile) {
        console.log('No mobile number available');
        setIsLoading(false);
        return;
      }
  
      const mobileNumber = homeData.mobile;
      const currentRole = homeData.role;
      const headers = {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.e30.FXibJVNHsvJ6Ff-N9XtTTom9cGExRqsldHbrhAOqRUg'
      };
  
      try {
        const userResponse = await fetch(
          `https://cmatraining.bahwancybertek.com/CP/api/LoginGIS/Login?Mobileno=${mobileNumber}`,
          { headers }
        );
        const userResponseData = await userResponse.json();
        console.log('Login Response:', userResponseData);
        
        const userId = userResponseData?.UserID || '';
        const orgId = userResponseData?.ULBDetails?.[0]?.OrgId || '';
        const orgCode = userResponseData?.ULBDetails?.[0]?.OrgCode || '';
        const orgName = userResponseData?.ULBDetails?.[0]?.OrgName;
        localStorage.setItem('organization_name', orgName || 'Organization');
        localStorage.setItem('org_code', orgCode);
  
        setUserData(prevData => ({
          ...prevData,
          userId,
          orgId,
          orgCode,
          organizationName: orgName || prevData.organizationName
        }));
  
        // First check: Is it CMA?
        if (orgCode === "CMA") {
          try {
            const orgResponse = await api.get('https://ras-tndma.in/api/v1/user/all-org');
            const orgData = orgResponse?.data?.data || [];
            
            setAssetReports(orgData);
  
            const totals = orgData.reduce((acc, org) => ({
              totalCompleted: acc.totalCompleted + (parseInt(org.completed) || 0),
              totalPending: acc.totalPending + (parseInt(org.pending) || 0)
            }), { totalCompleted: 0, totalPending: 0 });
  
            setSurveyStats({
              totalCompleted: totals.totalCompleted,
              totalPending: totals.totalPending,
              monthlyCount: totals.totalCompleted
            });
  
            setIsLoading(false);
            return;
          } catch (error) {
            console.error('Error fetching CMA org data:', error);
            setAssetReports([]);
            setSurveyStats({
              totalCompleted: 0,
              totalPending: 0,
              monthlyCount: 0
            });
            throw new Error('Failed to fetch CMA organization data');
          }
        }
        
        // For all other roles (including Revenue Assistant/Tax Collector)
        try {
          const zoneResponse = await fetch(
            `https://cmatraining.bahwancybertek.com/CP/api/Master/Drop_GISZoneUserID?OrgID=${orgId}&UserId=${userId}`,
            { headers }
          );
          const zoneData = await zoneResponse.json();
          console.log('Zone Data Response:', zoneData);

          if (zoneData && zoneData.length > 0) {
            const zoneId = zoneData[0].ZoneID || '';
            const zoneName = zoneData[0].ZoneName || 'No Zone Available';
            
            setUserData(prevData => ({
              ...prevData,
              zoneName,
              zoneId
            }));
            setZoneName(zoneName);

            try {
              const wardResponse = await fetch(
                `https://cmatraining.bahwancybertek.com/CP/api/Master/Drop_GISWardUserID?OrgID=${orgId}&UserID=${userId}&ZoneID=${zoneId}`,
                { headers }
              );
              const wardResponseData = await wardResponse.json();
              console.log('Ward Data Response:', wardResponseData);
              const wardData = wardResponseData || [];
              setWardData(wardData);

              // Different flow for Revenue Assistant/Tax Collector
              if (isAuthorizedRole(currentRole)) {
                if (wardData.length > 0) {
                  try {
                    const wardIds = wardData.map(ward => ward.WardID).join(',');
                    const summaryResponse = await api.get(`/api/v1/user/asst_smry?ward_ids=${wardIds}`);
                    const summaryData = summaryResponse?.data?.data || [];

                    const totals = summaryData.reduce((acc, ward) => ({
                      totalCompleted: acc.totalCompleted + (parseInt(ward.completed) || 0),
                      totalPending: acc.totalPending + (parseInt(ward.pending) || 0)
                    }), { totalCompleted: 0, totalPending: 0 });

                    const wardOrgData = summaryData.map(item => ({
                      ward_name: item.ward_name,
                      org_name: item.org_name
                    }));
                    localStorage.setItem('wardOrgData', JSON.stringify(wardOrgData));

                    setAssetReports(summaryData);
                    setSurveyStats({
                      totalCompleted: totals.totalCompleted,
                      totalPending: totals.totalPending,
                      monthlyCount: totals.totalCompleted
                    });
                  } catch (error) {
                    console.error('Ward Summary API Error:', error);
                    await fetchOrgSummary(orgName);
                  }
                }
                setSurveyParams({
                  wardData,
                  orgId,
                  userId,
                  zoneId,
                  organizationName: orgName
                });
                setIsSurveyEnabled(true);
              } 
              // For other roles, just get org summary after zone/ward data
              else {
                await fetchOrgSummary(orgName);
              }
            } catch (wardError) {
              console.error('Ward API Error:', wardError);
              await fetchOrgSummary(orgName);
            }
          } else {
            console.log('No zone data available, proceeding with org summary');
            await fetchOrgSummary(orgName);
          }
        } catch (zoneError) {
          console.error('Zone API Error:', zoneError);
          await fetchOrgSummary(orgName);
        }
        
      } catch (loginError) {
        console.error('Login API Error:', loginError);
        setGlobalError('Failed to authenticate user');
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      setGlobalError(error.message);
    } finally {
      setIsLoading(false);
    }
};

const fetchOrgSummary = async (orgName) => {
  try {
    const encodedOrgName = encodeURIComponent(orgName);
    const summaryResponse = await api.get(`https://ras-tndma.in/api/v1/user/asst_smry?org=${encodedOrgName}`);
    const summaryData = summaryResponse?.data?.data || [];

    if (summaryData && summaryData.length > 0) {
      setAssetReports(summaryData);
      const totals = summaryData.reduce((acc, ward) => ({
        totalCompleted: acc.totalCompleted + (parseInt(ward.completed) || 0),
        totalPending: acc.totalPending + (parseInt(ward.pending) || 0)
      }), { totalCompleted: 0, totalPending: 0 });

      setSurveyStats({
        totalCompleted: totals.totalCompleted,
        totalPending: totals.totalPending,
        monthlyCount: totals.totalCompleted
      });

      const wardOrgData = summaryData.map(item => ({
        ward_name: item.ward_name,
        org_name: item.org_name || orgName
      }));
      localStorage.setItem('wardOrgData', JSON.stringify(wardOrgData));
    } else {
      const emptyData = [{
        ward_name: 'No Data Available',
        completed: 0,
        pending: 0,
        total: 0,
        org_name: orgName,
        zone_name: 'N/A'
      }];
      setAssetReports(emptyData);
      setSurveyStats({
        totalCompleted: 0,
        totalPending: 0,
        monthlyCount: 0
      });
      localStorage.setItem('wardOrgData', JSON.stringify([{ ward_name: 'No Data Available', org_name: orgName }]));
    }
  } catch (error) {
    console.error('Organization Summary API Error:', error);
    const defaultData = [{
      ward_name: 'No Data Available',
      completed: 0,
      pending: 0,
      total: 0,
      org_name: orgName,
      zone_name: 'N/A'
    }];
    setAssetReports(defaultData);
    setSurveyStats({
      totalCompleted: 0,
      totalPending: 0,
      monthlyCount: 0
    });
    localStorage.setItem('wardOrgData', JSON.stringify([{ ward_name: 'No Data Available', org_name: orgName }]));
  }
};
  useEffect(() => {
    fetchData();
    return () => {
      dataFetchedRef.current = false;
    };
  }, []);

  const handleStartSurvey = () => {
    navigate('/filters', { 
      state: { 
        wardData,             
        orgId: userData.orgId,        
        userId: userData.userId,      
        zoneId: userData.zoneId,      
        organizationName: userData.organizationName,
        zoneName: userData.zoneName,
        orgCode: userData.orgCode 
      }
    });
  };
  const renderBasicUI = () => (
    <>
      <WelcomeHeader 
        userName={userName}
        isLoading={isLoading}
        userData={userData}
        userRole={userRole}
        wardData={wardData}
        handleStartSurvey={handleStartSurvey}
        isAuthorizedRole={isAuthorizedRole}
        isHighLevelRole={isHighLevelRole}
      />
  
      <UnauthorizedSurveyTable 
        isLoading={isLoading}
        assetReports={assetReports}
        onCompletedClick={handleCompletedClick}
      />
  
      <AssetDetailsModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        assetDetails={assetDetails}
        isLoading={isLoadingAssetDetails}
        wardInfo={selectedWard}
        onAssetClick={handleAssetClick}
      />
    </>
  );
  
  const renderFullUI = () => (
    <>
      <WelcomeHeader 
        userName={userName}
        isLoading={isLoading}
        userData={userData}
        userRole={userRole}
        wardData={wardData}
        handleStartSurvey={handleStartSurvey}
        isAuthorizedRole={isAuthorizedRole}
        isHighLevelRole={isHighLevelRole}
      />
      <StatsCards isLoading={isLoading} surveyStats={surveyStats} />
  
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <AssetDetailsSection 
          isLoading={isLoading}
          assetReports={assetReports}
          onCompletedClick={handleCompletedClick}
        />
  
        <SurveyBarChart
          isLoading={isLoading}
          dailySurveyData={dailySurveyData}
          assetReports={assetReports}
        />
      </div>
  
      <AssetDetailsModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        assetDetails={assetDetails}
        isLoading={isLoadingAssetDetails}
        wardInfo={selectedWard}
        onAssetClick={handleAssetClick}
      />
    </>
  );
  
  const renderHighLevelUI = () => (
    <>
      <WelcomeHeader 
        userName={userName}
        isLoading={isLoading}
        userData={userData}
        userRole={userRole}
        wardData={wardData}
        handleStartSurvey={handleStartSurvey}
        isAuthorizedRole={isAuthorizedRole}
        isHighLevelRole={isHighLevelRole}
      />
  
      <OrgSummaryTable 
        data={assetReports} 
        isLoading={isLoading} 
        onNavigateToReport={handleAssetClick} // Add this prop
      />
    </>
  );
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {globalError && (
          <div className="mb-4">
            <ErrorMessage message={globalError} />
          </div>
        )}
        
        {userData.orgCode === "CMA" ? renderHighLevelUI() :
         isAuthorizedRole(userRole) ? renderFullUI() : 
         renderBasicUI()}
      </div>
    </div>
  );
};

export default HomePage;